<template>
  <div class="content-wrapper store-sale-orders">
    <section class="content" style="margin: 0 10px">
      <breadcrumb />
      <div class="top-btns mb10">
        <a-button type="primary" @click="showWindow = true">
          <i class="iconfont icon-new mr5"></i>
          {{ $t(`批量录单`) }}
        </a-button>
      </div>
      <div class="form-content" @keydown="enterPress">
        <a-row style="margin-bottom: 1%" :gutter="16">
          <a-col :span="6">
            <!-- 原始订单号 -->
            <a-input
              allowClear
              type="text"
              :placeholder="$t(`原始订单号/OMS订单号/快递面单号，多个用英文逗号或空格分隔`)"
              v-model.trim="userInputOrderIds"
            />
          </a-col>
          <a-col :span="6">
            <!-- 选择店铺 -->
            <template v-if="showShop">
              <SelectShop :placeholder="$t(`请选择`) + $t(`店铺`)" @selected="selectShopData" />
            </template>
          </a-col>
          <a-col :span="6">
            <!-- 创建时间 -->
            <DateRangePicker
              id="orderTime"
              :needhms="true"
              @get_date="getOrderTime"
              :placeholder="$t(`创建时间`)"
              :start-time="searchData.startOrderTime"
              :end-time="searchData.endOrderTime"
            />
          </a-col>
          <a-col :span="6">
            <!-- 按钮 -->
            <a-button @click="getTableList(1)" type="primary">
              <i class="iconfont icon-search mr5"></i>
              {{ $t(`查询`) }}
            </a-button>
            <a-button @click="reset">
              <i class="iconfont icon-synchronize mr5"></i>
              {{ $t(`重置`) }}
            </a-button>
            <!-- <a-button @click="exportHandle" type="primary">
              <i class="iconfont icon-upload mr5"></i>
              {{ $t(`导出`) }}
            </a-button> -->
          </a-col>
        </a-row>
      </div>
      <div class="table-content">
        <div class="table-body">
          <a-table
            :bordered="false"
            :columns="columns"
            :expandIconAsCell="false"
            :dataSource="tableList"
            :pagination="false"
            :scroll="{ y: tableHeight }"
            :rowKey="(record, index) => index"
          >
            <!-- 原始订单号 -->
            <template slot="platformOrderId" slot-scope="text, record">
              <span>
                {{ record.originOrder.platformOrderId }}
                <a @click="Clone(text, record)" style="margin-right: 5px">
                  <a-icon class="text-main" type="copy" />
                </a>
                <a-tag
                  color="orange"
                  v-if="platformOrderStatus[record.originOrder.platformOrderStatus]"
                >
                  {{ platformOrderStatus[record.originOrder.platformOrderStatus] }}
                </a-tag>
              </span>
              <br />
              <!-- 判断OMS订单是否存在 -->
              <a
                v-if="record.orderInfos && record.orderInfos.length > 0"
                class="detail-link"
                :class="{ visited: hasVisited(record) }"
                @click="jumpDetails(record)"
              >
                {{ record.orderInfos[0].orderInfo?.orderSn }}
              </a>
            </template>

            <!-- 店铺 -->
            <template slot="ShopName" slot-scope="text, record">
              <div>{{ record.originOrder.shop.shopName }}</div>
              <div>
                <a-tag v-if="record.originOrder.distributor">
                  {{ record.originOrder.distributor.distributorName }}
                </a-tag>
              </div>
              <div class="gray">
                <nobr>{{ $t(`付`) }}：{{ record.originOrder.payTime }}</nobr>
              </div>
            </template>

            <!-- 应收金额 -->
            <template slot="PaymentMethod" slot-scope="text, record">
              <span>
                {{ parseFloat(record.originOrder.totalFee).toFixed(2) }}
              </span>
              <a-tag color="orange" v-if="record.originOrder.payType">
                {{ record.originOrder.payType }}
              </a-tag>
              <span v-else></span>
            </template>

            <!-- 订单时间-->
            <template slot="OrderTime" slot-scope="text, record">
              {{ record.originOrder.platformOrderTime }}
            </template>

            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <a-table
                style="padding: 10px"
                :bordered="false"
                :pagination="false"
                :columns="columnsSon"
                :dataSource="record.originOrder.orderGoodses"
                :rowKey="(record, index) => index"
              >
                <!-- 商家编码-->
                <template slot="ProductId" slot-scope="res">
                  <template v-if="!res.editable">{{ res.outerCode }}</template>
                  <template v-else>
                    <input type="text" v-model="goodsModify.outerCode" />
                  </template>
                  <template
                    v-if="record.originOrder && record.originOrder.transferStatus == 'ERROR'"
                  >
                    <a
                      href="#"
                      v-if="!res.editable"
                      @click.prevent="editSkuCode(res)"
                      style="margin-left: 5px"
                    >
                      <a-icon type="edit" />
                    </a>
                    <a
                      href="#"
                      v-if="res.editable"
                      @click.prevent="confirmSkuCode(res)"
                      style="margin-left: 5px"
                    >
                      <a-icon type="check-circle" class="mr10 ml6" />
                    </a>
                    <a href="#" v-if="res.editable" @click.prevent="cancelSkuCode(res)">
                      <a-icon type="close-circle" />
                    </a>
                  </template>
                </template>

                <!-- 商品名称-->
                <template slot="ProductName" slot-scope="text, record">
                  {{ record.title }}
                </template>

                <!-- 数量-->
                <template slot="Quantity" slot-scope="text, record">
                  {{ record.num }}
                </template>

                <!-- 单价-->
                <template slot="Price" slot-scope="text, record">
                  {{ record.price }}
                </template>

                <!-- 优惠金额-->
                <template slot="DiscountFee" slot-scope="text, record">
                  {{ record.discountFee }}
                </template>

                <!-- 小计-->
                <template slot="TotalFee" slot-scope="text, record">
                  {{ record.totalFee }}
                </template>
              </a-table>
            </div>
          </a-table>
        </div>
        <div class="table-footer clearfix">
          <Pagination
            :offset="searchData.offset"
            :length="searchData.length"
            :total-count="totalCount"
            @paginate="paginate"
          />
        </div>
      </div>
    </section>
    <!-- 批量录单 -->
    <a-modal v-model="showWindow" :title="$t(`批量录单`)" :width="500" :footer="null">
      <div class="upload-content">
        <a-upload-dragger name="file" :fileList="fileList" action="" :customRequest="handleChange">
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" />
          </p>
          <p class="ant-upload-hint">{{ $t('点击选择或者拖拽要上传的文件') }}</p>
        </a-upload-dragger>
        <a v-if="xlsDownloadUrl" target="_blank" class="text-info mt10" :href="xlsDownloadUrl">
          {{ $t('批量录单') }}{{ $t('模板') }}.xls
        </a>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import DateRangePicker from '@component/dateRangePicker'
import SelectShop from '@component/selectShop'
import Pagination from '@component/pagination'
import { getDateStr } from '@common/getDateStr'
import http from '@service/axios'
import api from '@service/api'
import { copy } from '@common'
export default {
  name: 'StoreSaleOrders',
  components: { DateRangePicker, SelectShop, Pagination },
  data() {
    return {
      userInputOrderIds: undefined,
      searchData: {
        //searchData
        orderIds: [], //    订单号列表
        startOrderTime: undefined, //   订单创建时间（开始）
        endOrderTime: undefined, // 订单创建时间（结束）
        startPayTime: undefined, //    订单付款时间（开始）
        endPayTime: undefined, //  订单付款时间（结束）
        startReceiveTime: undefined, //签收时间（开始）
        endReceiveTime: undefined, //签收时间（结束）
        offset: 0,
        length: 10,
      },
      columns: [
        {
          title: this.$t('订单号'),
          key: 'platformOrderId',
          width: 200,
          scopedSlots: { customRender: 'platformOrderId' },
        },
        {
          title: this.$t('门店'),
          dataIndex: 'ShopName',
          scopedSlots: { customRender: 'ShopName' },
        },
        {
          title: this.$t('收款金额'),
          dataIndex: 'PaymentMethod',
          scopedSlots: { customRender: 'PaymentMethod' },
        },
        {
          title: this.$t('订单时间'),
          width: 200,
          scopedSlots: { customRender: 'OrderTime' },
          dataIndex: 'OrderTime',
        },
        // {
        //   title: this.$t('创建者'),
        //   dataIndex: 'creator',
        // },
      ],
      columnsSon: [
        {
          title: this.$t('商家编码'),
          key: 'ProductId',
          scopedSlots: { customRender: 'ProductId' },
        },
        {
          title: this.$t('商品名称'),
          key: 'ProductName',
          scopedSlots: { customRender: 'ProductName' },
        },
        {
          title: this.$t('数量'),
          key: 'Quantity',
          scopedSlots: { customRender: 'Quantity' },
        },
        {
          title: this.$t('单价'),
          dataIndex: 'price',
          scopedSlots: { customRender: 'Price' },
        },
        {
          title: this.$t('优惠金额'),
          dataIndex: 'discountFee',
          scopedSlots: { customRender: 'DiscountFee' },
        },
        {
          title: this.$t('收款小计'),
          dataIndex: 'totalFee',
          scopedSlots: { customRender: 'TotalFee' },
        },
      ],
      tableList: [],
      totalCount: 0,
      showWindow: false,
      fileList: [],
      visitedList: [],
    }
  },
  computed: {
    platformOrderStatus() {
      //平台订单状态
      return {
        WAIT_CHECK: this.$t(`待审核`), //待审核
        CHECK_PASS: this.$t(`审核通过`), //审核通过
        REJECT: this.$t(`已拒收`), //已驳回
        CLOSE_PLATFORM: this.$t(`平台关闭`), //平台关闭
        CLOSED_MANUAL: this.$t(`人工关闭`), //人工关闭
        INVALID: this.$t(`无效`), //无效
        CLOSE_PLATFORM_PAID: this.$t(`已付款，但平台关闭`), //平台已付款关闭
        DELIVERY_SUCCESS: this.$t(`发货成功`), //发货成功
        DELIVERY_FAIL: this.$t(`发货失败`), //发货失败
        WAIT_BUYER_PAY: this.$t('待付款'), //待付款
        WAIT_SELLER_SEND_GOODS: this.$t(`待发货`), //待发货
        TRADE_FINISHED: this.$t(`交易结束`), //交易结束
        WAIT_BUYER_CONFIRM_GOODS: this.$t(`已发货`), //已发货
        TRADE_CLOSED: this.$t(`平台关闭`), //平台关闭
        TRADE_CLOSED_BY_PLATFORM: this.$t(`平台关闭，待付款`), //平台关闭，待付款
      }
    },
    xlsDownloadUrl() {
      let url
      if (this.$i18n.locale === 'zh') {
        url =
          'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Chinese/%E6%89%B9%E9%87%8F%E5%BD%95%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx'
      } else if (this.$i18n.locale === 'en') {
        url =
          'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/English/Batch%20Entry%20Orders%20Template.xlsx'
      } else {
        url =
          'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Thailand/%E0%B9%80%E0%B8%97%E0%B8%A1%E0%B9%80%E0%B8%9E%E0%B8%A5%E0%B8%95%E0%B8%99%E0%B8%B3%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C.xlsx'
      }
      return url
    },
    tableHeight() {
      return (window.innerHeight - 340) * this.$store.state.rate
    },
  },
  mounted() {
    this.searchData.startOrderTime = getDateStr(-7, true)
    this.searchData.endOrderTime = getDateStr(1, false) + ' 00:00:00'
    this.getTableList()
  },
  methods: {
    ...mapMutations(['addPane']),
    //一键复制功能
    Clone(text) {
      this.copyData = text.originOrder.platformOrderId
      copy(this.copyData)
    },
    enterPress() {
      var e = event || window.event
      if (e.keyCode == 13) {
        this.getTableList(true)
      }
    },
    selectShopData(data) {
      this.searchData.shopCode = data.code
      this.searchData.platform = data.platform
    },
    getOrderTime(date) {
      this.searchData.startOrderTime = date.start_time
      this.searchData.endOrderTime = date.end_time
    },
    reset() {
      for (let item in this.searchData) {
        this.searchData[item] = undefined
      }
      this.searchData.startOrderTime = getDateStr(-7, true)
      this.searchData.endOrderTime = getDateStr(1, false) + ' 00:00:00'
      this.searchData.offset = 0
      this.searchData.length = 10
      this.getTableList(1)
    },
    exportHandle() {},
    getDateDiff(startDate, endDate) {
      var startOrderTime = new Date(Date.parse(startDate.replace(/-/g, '/'))).getTime()
      var endOrderTime = new Date(Date.parse(endDate.replace(/-/g, '/'))).getTime()
      var dates = Math.abs(startOrderTime - endOrderTime) / (1000 * 60 * 60 * 24)
      return dates
    },
    getTableList(type) {
      if (this.searchData.startPayTime) {
        var t = this.getDateDiff(this.searchData.startPayTime, this.searchData.endPayTime)
        if (t > 31) {
          this.$warning(this.$t(`付款时间间隔不能超过一个月！`))
          return
        }
      }
      if (type) {
        //点击了查询按钮
        this.searchData.offset = 0
      }
      var searchDataTemp = Object.assign({}, this.searchData)
      if (this.userInputOrderIds != undefined) {
        searchDataTemp.orderIds = this.userInputOrderIds.split(',')
        searchDataTemp.startOrderTime = undefined
        searchDataTemp.endOrderTime = undefined
      } else {
        searchDataTemp.orderIds = []
      }
      http({
        url: api.getOriginOrderList,
        type: 'post',
        data: { ...searchDataTemp, platform: 'RETAIL' },
        hasLoading: true,
        success: (res) => {
          let result = res.data || res.result
          this.tableList = result.content || []
          this.totalCount = result.totalCount
        },
      })
    },
    handleChange(info) {
      var size = Math.floor(info.file.size / 1024)
      if (size > 20 * 1024) {
        this.$warning(this.$t(`上传文件大小不能大于20M！`))
        return false
      }
      var filetypes = ['.xls', '.xlsx']
      var isnext = false
      var fileend = info.file.name.substring(info.file.name.lastIndexOf('.'))
      for (var i = 0; i < filetypes.length; i++) {
        if (filetypes[i] == fileend) {
          isnext = true
          break
        }
      }
      if (!isnext) {
        this.$warning(this.$t(`不接受此文件类型！`))
        info.file = undefined
        return false
      }
      const form = new FormData()
      form.append('file', info.file)
      form.append('batchType', 'RETAIL')
      http({
        url: api.uploadBatchOrderFile,
        type: 'post',
        contentType: false,
        processData: false,
        showErrorMessage: true,
        data: form,
        hasLoading: true,
        success: () => {
          this.$success(this.$t(`上传成功！`))
          this.showWindow = false
          this.fileList = []
        },
        fail: (res) => {
          this.$message.error(res.subMsg || res.msg)
          this.showWindow = false
          this.fileList = []
        },
      })
    },
    paginate(data) {
      this.searchData.offset = data.offset
      this.searchData.length = data.length
      this.getTableList()
    },
    hasVisited(record) {
      let orderInfo = record.orderInfos[0]?.orderInfo || {}
      return this.visitedList.includes(orderInfo.orderSn)
    },
    jumpDetails(record) {
      let orderInfo = record.orderInfos[0]?.orderInfo,
        orderId = orderInfo.orderId || '',
        orderSn = orderInfo.orderSn
      this.visitedList.push(orderSn)
      this.addPane({
        view: 'orderDetails/' + orderSn,
        name: '订单详情',
        params: {
          orderId,
          orderSn,
        },
        route: '/orderManage/omsOrderDetail/orderDetails/' + orderId + '?orderSn=' + orderSn,
      })
    },
  },
}
</script>
